<template>
  <table class="table table-sm table-bordered table-sm">
    <thead>
      <tr>
        <th>E-Mail</th>
        <th width="15%">Rolle</th>
        <th class="text-center" width="1%">Status</th>
        <th class="text-center" width="1%">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.uid">
        <td>{{user.email}}</td>
        <td>
          <select
            name="role"
            class="form-control form-control-sm"
            :disabled="requesting || currentUser === user.uid"
            :value="user.customClaims && user.customClaims.role || ''"
            @change="onRoleChange(user.uid, $event.target.value)"
          >
            <option :value="role" v-for="role in roles" :key="role">{{role | role}}</option>
          </select>
        </td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-block"
            :class="user.disabled ? 'btn-secondary': 'btn-success'"
            :disabled="requesting || currentUser === user.uid"
            @click="onToggleDisabled(user.uid, user.disabled)"
          >{{ user.disabled ? 'Inaktiv' : 'Aktiv'}}</button>
        </td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-danger btn-block"
            :disabled="requesting || currentUser === user.uid"
            @click="onDelete(user.uid)"
          >
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'users-table',
  props: {
    currentUser: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    requesting: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onDelete(uid) {
      this.$emit('deleteUser', uid);
    },
    onRoleChange(uid, role) {
      this.$emit('updateUserRole', { uid, role });
    },
    onToggleDisabled(uid, disabled) {
      this.$emit('toggleUserDisabled', { uid, disabled });
    }
  }
};
</script>

